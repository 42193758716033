import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { db } from '../../firestore.js';
import { getDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

function TrendingTags() {
  const [tags, setTags] = useState([]);
  const largeScreen = useMediaQuery('(min-width:1350px)');
  const mediumScreen = useMediaQuery('(min-width:650px)');

  useEffect(() => {
    const fetchTags = async () => {
      const docRef = doc(db, 'util', 'topTags');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setTags(docSnap.data().topTags);
      } else {
        console.log('No such document!');
      }
    };

    fetchTags();
  }, []);

  return (
    <Box
      mt={-2}
      mb={4}
      display='flex'
      flexDirection='column'
      alignItems='center'
      sx={{
        marginLeft: largeScreen ? 18 : mediumScreen ? 10 : 0,  // Adjust left margin
        marginRight: largeScreen ? 18 : mediumScreen ? 10 : 0, // Adjust right margin
      }}
    >
      <Stack
        direction='row'
        justifyContent='center'
        gap={{ xs: 1, sm: 1 }} 
        flexWrap='wrap'
        maxWidth={'90%'} 
      >
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            component={Link}
            to={`/s/${tag}`}
            clickable
            sx={{
              '&:hover': {
                backgroundColor: '#6b1e1e',
                color: '#f0f0f0',
              },
            }}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default TrendingTags;
