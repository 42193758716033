import React, { useEffect, useState } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firestore.js';
import styled from 'styled-components';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const EditableInput = styled.input`
  width: 80%; // this is the normal width
  transition: width 0.3s; // to animate the width change

  &:focus {
    outline: none !important;
    border:1px solid #6b1e1e;
    box-shadow: 0 0 5px #6b1e1e;
    width: 100%; // this is the width on focus
  }
`;
const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
`;

function ConversationTitle({ postId, userId, profile }) {
    const [title, setTitle] = useState('');
    const [prevTitle, setPrevTitle] = useState('');
    const [editing, setEditing] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' }); // Add state for alert

    useEffect(() => {
        if (postId && userId) {
            let collectionName = postId.length === 20 ? 'posts' : 'privatePosts';
            const docRef = doc(db, collectionName, postId);

            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    setTitle(docSnap.data().title);
                } else {
                    setTitle('Post not found');
                }
            });
        }
    }, [postId, userId]);

    const handleDoubleClick = () => {
        if (profile === userId) {
            setPrevTitle(title);
            setEditing(true);
        }
    };

    const handleBlur = () => {
        updateTitle();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            updateTitle();
        }
    };

    const updateTitle = () => {
        if (title.length <= 150) {
            if (title !== prevTitle) {
                fetch(`https://aiarchives-375517.uc.r.appspot.com/api/setTitle/${postId}/${userId}/${title}`, {
                    method: 'POST',
                })
                .then(response => response.json())
                .then(data => {
                    if (data.code === 200) {
                        setEditing(false);
                        setAlert({ open: true, message: 'Title was edited successfully!', severity: 'success' }); // Set success alert
                    } else {
                        setTitle(prevTitle);
                        setEditing(false);
                        setAlert({ open: true, message: data.message, severity: 'error' }); // Set error alert with message from server
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setTitle(prevTitle);
                    setEditing(false);
                    setAlert({ open: true, message: 'An error occurred while updating the title.', severity: 'error' }); // Set error alert with generic message
                });
            } else {
                setEditing(false); // no need to change the title, so we just remove the editing state
            }
        } else {
            setTitle(prevTitle);
            setAlert({ open: true, message: 'Title cannot exceed 150 characters', severity: 'error' });
        }
    };
    

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert((prevAlert) => ({ ...prevAlert, open: false }));
    };

    const titleStyle = {
        textAlign: 'center',
        fontSize: '1.8em', // adjust the font size directly in the inline style
        width: '50%', // add this line
    };
    

    const centerText = {
        textAlign: 'center',
    };

    let displayTitle = title.length > 150 ? title.substring(0, 150) + '...' : title;

    return (
        <>
            <TitleContainer>
                <Snackbar open={alert.open} autoHideDuration={2000} onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
                        {alert.message}
                    </Alert>
                </Snackbar>
                <h1 style={titleStyle} onDoubleClick={handleDoubleClick}>
                    {editing ? (
                        <EditableInput
                            type="text"
                            value={title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            autoFocus
                        />
                    ) : (
                        displayTitle
                    )}
                </h1>
            </TitleContainer>
            <div style={centerText}>
                {profile === userId && !editing && <small>(double click to edit)</small>}
            </div>
        </>
    );
}

export default ConversationTitle;
