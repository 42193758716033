import { db } from '../../firestore.js';
import { collection, doc, setDoc } from 'firebase/firestore';
import { SET_USER_LOGOUT_SUCCESS, SET_USER_LOGIN_SUCCESS } from '../types';

export const createNewUser = (profile) => async (dispatch) => {
  let userRef = collection(db, 'users');
  await setDoc(
    doc(userRef, profile.uid),
    {
      email: profile.email,
      name: profile.displayName,
      photoURL: profile.photoURL,
      uid: profile.uid,
      lastSeen: new Date().getTime(),
    },
    { merge: true }
  );
  dispatch({
    type: SET_USER_LOGIN_SUCCESS,
    payload: true,
  });
};

export const setUserLogout = () => {
  return {
    type: SET_USER_LOGOUT_SUCCESS,
    payload: null,
  };
};

// Set codeValid to true
export const setUserLogin = (action) => {
  return {
    type: SET_USER_LOGIN_SUCCESS,
    payload: action,
  };
};
