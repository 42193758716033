import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Outlet } from 'react-router-dom';
import AdminHeader from '../../components/Headers/AdminHeader';
import AdminFooter from '../../components/Footers/AdminFooters';
import Navbar from '../../components/Navbar/Navbar';

const Admin = ({ navExpand }) => {
  return (
    <Fragment>
      <Navbar />
      <div className={navExpand ? 'main-expand' : 'main'}>
        <div className='container-fluid'>
          <AdminHeader />
          <Outlet />
          {/* <AdminFooter /> */}
        </div>
      </div>
    </Fragment>
  );
};

Admin.propTypes = {
  navExpand: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  navExpand: state.layout.navExpand,
});

export default connect(mapStateToProps)(Admin);
