import React, { useEffect, useState, Fragment } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firestore.js';

const AdminHeader = () => {
  const [profile, setProfile] = useState(false);
  const [posts, setPosts] = useState(0);
  const [views, setViews] = useState(0);
  const [bookMarked, setbookMarked] = useState(0);

  const abbreviateNumber = (num) => {
    if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
    return num;
  }

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      if (user) {
        setProfile({ uid: user.uid });
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  useEffect(() => {
    setViews(0);
    if (profile?.uid) {
      const userRef = doc(db, 'users', profile?.uid);
      getDoc(userRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data()?.savedPosts) {
          setbookMarked(Object.keys(docSnap.data().savedPosts).length);
        }
        if (docSnap.exists() && docSnap.data()?.convPostMap) {
          setPosts(Object.keys(docSnap.data().convPostMap).length);
          Object.values(docSnap.data().convPostMap).forEach((postId) => {
            const docRef = doc(db, 'posts', postId);
            getDoc(docRef).then((docSnap) => {
              if (docSnap.exists()) {
                const postView = docSnap.data().views || 1;
                setViews((views) => views + postView);
              }
            });
          });
        }
      });
    }
  }, [profile?.uid]);

  return (
    <Fragment>
      <div style={header}>
        <div style={headerBody}>
          <Row>
            <Col md='6' xl='4'>
              <Card style={card}>
                <CardBody>
                  <Row>
                    <div className='col'>
                      <span
                        style={{ fontSize: '4.5rem' }}
                        className='h1 font-weight-bold mb-0 mt-3 d-flex justify-content-center'
                      >
                        {abbreviateNumber(posts)}
                      </span>
                    </div>
                  </Row>
                  <p className='mt-3 mb-4 d-flex justify-content-center'>
                    <span className='h4 font-weight-bold'>Posts</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md='6' xl='4'>
              <Card style={card}>
                <CardBody>
                  <Row>
                    <div className='col'>
                      <span
                        style={{ fontSize: '4.5rem' }}
                        className='h1 font-weight-bold mb-0 mt-3 d-flex justify-content-center'
                      >
                        {abbreviateNumber(bookMarked)}
                      </span>
                    </div>
                  </Row>
                  <p className='mt-3 mb-4 d-flex justify-content-center'>
                    <span className='h4 font-weight-bold'>Saved</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md='6' xl='4'>
              <Card style={card}>
                <CardBody>
                  <Row>
                    <div className='col'>
                      <span
                        style={{ fontSize: '4.5rem' }}
                        className='h1 font-weight-bold mb-0 mt-3 d-flex justify-content-center'
                      >
                        {abbreviateNumber(views)}
                      </span>
                    </div>
                  </Row>
                  <p className='mt-3 mb-4 d-flex justify-content-center'>
                    <span className='h4 font-weight-bold'>Views</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

const header = {
  paddingBottom: '4.5rem',
  paddingTop: '4.5rem',
  position: 'relative',
};

const headerBody = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: 'rgb(82, 95, 127)',
  textAlign: 'left',
};

const card = {
  marginBottom: '30px',
  boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.15)',
  border: '0',
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: 'rgb(82, 95, 127)',
  textAlign: 'left',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '0',
  backgroundColor: '#fff',
  backgroundClip: 'borderBox',
  borderRadius: '0.5rem',
};

export default AdminHeader;
