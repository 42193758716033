import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Home from './pages/Home/Home';
import SignIn from './pages/SignIn/SignIn';
import Conversation from './pages/Conversation/Conversation';
import Tag from './pages/Tag/Tag';
import Policy from './pages/Policy/Policy';
import Admin from './layouts/Admin/Admin';
import ProtectedRoute from './layouts/ProtectedRoute';
import Dashboard from './layouts/Dashboard/Dashboard';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/id/:id' exact element={<Conversation />} />
        <Route path='/s/:tag' exact element={<Tag />} />
        <Route path='/s' exact element={<Tag />} />
        <Route path='/signin' exact element={<SignIn />} />
        <Route path='/policy' exact element={<Policy />} />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        >
          <Route path='' exact element={<Dashboard />} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  </Provider>
);

serviceWorker.unregister();
