import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, CircularProgress, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firestore.js';

function DashboardAutoComplete({ profile }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [fuse, setFuse] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      if (profile?.uid) {
        const userRef = doc(db, 'users', profile?.uid);
        try {
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();

            const fetchAndTransformPost = async (postId) => {
              let docRef = '';
              if (postId?.length === 22) {
                docRef = doc(db, 'privatePosts', postId);
              } else {
                docRef = doc(db, 'posts', postId);
              }
              const postSnap = await getDoc(docRef);
              if (postSnap.exists()) {
                return {
                  title: postSnap.data().title,
                  postId: postSnap.data().postId,
                  model: postSnap.data().tags[0],
                };
              }
              return null;
            };

            let postIds = [];
            if (userData?.convPostMap) {
              postIds = postIds.concat(Object.values(userData.convPostMap));
            }
            if (userData?.savedPosts) {
              postIds = postIds.concat(userData.savedPosts);
            }

            // Remove duplicates from the postIds array
            postIds = [...new Set(postIds)];

            const fetchPostPromises = postIds.map(fetchAndTransformPost);
            const results = await Promise.all(fetchPostPromises);
            const transformedData = results.filter(result => result !== null); // Filter out null results

            setFuse(new Fuse(transformedData, {
              shouldSort: true,
              minMatchCharLength: 3,
              includeScore: true,
              threshold: .2,
              ignoreLocation: true,
              keys: ['title']
            }));
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error:', error);
          setIsLoading(false);
        }
      }
    };

    fetchPosts();
  }, [profile?.uid, db]);



  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '10px',
          p: 3,
        }}
      >
        <Typography variant="body1">
          Search bar is loading...
        </Typography>
        <CircularProgress size={20} style={{ color: '#6b1e1e' }} />
      </Box>
    );
  }

  const handleSelection = (event, newValue) => {
    setSelectedOption(newValue);
    if (newValue) {
      window.open(`/id/${newValue.postId.trim()}`, '_blank');
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (newInputValue && fuse) {
      const results = fuse.search(newInputValue);
      results.sort((a, b) => a.score - b.score);
      setSearchResults(results.filter(({ score }) => score < 0.3).map(({ item }) => ({ item, inputValue: newInputValue })));
    } else {
      setSearchResults([]);
    }
  };

  const highlightMatch = (str, inputValue) => {
    const regex = new RegExp(`(${inputValue})`, 'gi');
    const match = str.match(regex);

    if (match) {
      const matchIndex = str.indexOf(match[0]);
      const matchEndIndex = matchIndex + inputValue.length;
      let start = matchIndex - Math.floor((150 - inputValue.length) / 2);
      let end = matchEndIndex + Math.ceil((150 - inputValue.length) / 2);

      if (start < 0) {
        end -= start;
        start = 0;
      }

      if (end > str.length) {
        start -= (end - str.length);
        end = str.length;
      }

      let displayTitle = str;

      if (str.length > 150) {
        displayTitle = `${start > 0 ? '...' : ''}${str.slice(start, end)}${end < str.length ? '...' : ''}`;
      }

      const highlighted = displayTitle.replace(regex, '<b>$1</b>');

      return { highlighted, matchIndex };
    } else {
      return { highlighted: str, matchIndex: -1 };
    }
  };


  return (
    <Box sx={{ p: 3, display: 'flex' }}>
      <Autocomplete
        value={selectedOption}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelection}
        options={searchResults.map(({ item }) => item)}
        fullWidth
        sx={{ width: '100%' }}
        noOptionsText={inputValue === ''
          ? <span style={{ fontStyle: 'italic' }}>Type in a keyword</span>
          : <span style={{ fontStyle: 'italic' }}>No matching keywords</span>
        }
        isOptionEqualToValue={(option, value) => option.postId === value.postId}
        getOptionLabel={(option) => option.title.length > 200 ? option.title.slice(0, 197) + '...' : option.title}
        renderInput={(params) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              {...params}
              label="Search by Prompts"
              sx={{
                color: '#6B1E1E',
                '& label.Mui-focused': {
                  color: '#6B1E1E',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#6B1E1E',
                },
              }}
            />
          </Box>
        )}
        renderOption={(props, option, { inputValue }) => {
          const { highlighted: highlightedTitle, matchIndex } = highlightMatch(option.title, inputValue);
          const abbreviatedPostId = option.postId.substring(0, 8).padEnd(8);
          let backgroundColor;
          let color;
          let outline;
          
          switch (option.model) {
            case 'ChatGPT-4':
              backgroundColor = '#000000';
              color = '#ffffff';
              break;
            case 'Bard':
              backgroundColor = '#f54842';
              color = '#ffffff';
              break;
            case 'Claude':
              backgroundColor = '#ffffff';
              color = '#000000';
              outline = '1px solid #000000';
              break;
            case 'Meta':
              backgroundColor = '#4267B2';
              color = '#ffffff';
              break;
            default:
              backgroundColor = '#213860';
              color = '#ffffff';
          }
          
          return (
            <li {...props} key={option.postId}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{
                  marginRight: '10px',
                  fontWeight: 'bold',
                  fontFamily: 'monospace',
                  backgroundColor: backgroundColor,
                  color: color,
                  borderRadius: '5px',
                  padding: '2px 4px',
                  outline: outline,
                }}>
                  {abbreviatedPostId}
                </div>
                <div dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
              </div>
            </li>
          );
        }}
      />
    </Box>
  );
}

export default DashboardAutoComplete;