import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { showNavbar } from '../../redux/actions/layout';
import { Link } from 'react-router-dom';
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';
import { BiExit } from 'react-icons/bi';
import AdminRoutes from '../../layouts/AdminRoutes';
import { IconContext } from 'react-icons';
import { signOut } from 'firebase/auth';
import { firebase_auth } from '../../firestore';

const Navbar = ({ navExpand, showNavbar }) => {
  return (
    <Fragment>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          <nav className='nav-fix'>
            <ul className='nav-menu-items'>
              {AdminRoutes.map((item, index) => {
                return (
                  <li key={index} className='nav-text'>
                    <Link to={item.path}>{item.icon}</Link>
                  </li>
                );
              })}
              <li className='nav-text'>
                <Link to='/'>
                  <BiExit onClick={() => signOut(firebase_auth)} />
                </Link>
              </li>
              {navExpand ? (
                <Link to='#' className='menu-bars'>
                  <FiChevronsLeft onClick={() => showNavbar(false)} />
                </Link>
              ) : (
                <Link to='#' className='menu-bars'>
                  <FiChevronsRight onClick={() => showNavbar(true)} />
                </Link>
              )}
            </ul>
          </nav>
        </div>
        <div
          style={{ left: '62px' }}
          className={
            navExpand ? 'navbar navbar-expand' : 'navbar navbar-shrink'
          }
        >
          <nav className={navExpand ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items'>
              {AdminRoutes.map((item, index) => {
                return (
                  <li key={index} className='nav-text'>
                    <Link to={item.path}>
                      <span>{item.name}</span>
                    </Link>
                  </li>
                );
              })}
              <li className='nav-text'>
                <Link to='/'>
                  <span onClick={() => signOut(firebase_auth)}>Sign out</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </IconContext.Provider>
    </Fragment>
  );
};

Navbar.propTypes = {
  showNavbar: PropTypes.func.isRequired,
  navExpand: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  navExpand: state.layout.navExpand,
});

export default connect(mapStateToProps, {
  showNavbar,
})(Navbar);
