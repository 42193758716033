import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Policy = () => {
  useEffect(() => {
    // this will redirect to the privacy.html file located in the public directory
    window.location.href = "/policy.html";
  }, []);

  return null;
};

export default Policy;