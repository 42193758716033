import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Tag = styled.span`
  position: absolute;
  bottom: 5px;
  margin-right: 5px;
  margin-left: 10px;
  background-color: #213860;
  color: #e6e6e5;
  padding: 7px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  border: ${(props) => props.border || 'none'}; /* Add border styling */
`;

function TagCol({ postId, tags }) {
  const [state, setState] = useState({
    text: '',
    backgroundColor: '#E6E6E5',
    color: '#e6e6e5',
    border: 'none',
  });

  function getStyles(text) {
    if (text.includes('Claude')) {
      return { backgroundColor: '#FFFFFF', color: '#000000', border: '1px solid black' };
    } else if (text.includes('ChatGPT-4')) {
      return { backgroundColor: '#000000', color: '#e6e6e5' };
    } else if (text.includes('Gemini')) {
      return { backgroundColor: '#f54842', color: '#e6e6e5' };
    } else if (text.includes('Meta')) {
      return { backgroundColor: '#4267B2', color: '#e6e6e5' };
    } else {
      return { backgroundColor: '#213860', color: '#e6e6e5' };
    }
  }

  useEffect(() => {
    let tagText = tags[0];
    // Check if the tag text is "Bard" and change it to "Gemini"
    if (tagText === 'Bard') {
      tagText = 'Gemini';
    }
    const styles = getStyles(tagText);
    setState({
      text: tagText,
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      border: styles.border,
    });
  }, [postId, tags]);

  return (
    <Tag
      style={{
        backgroundColor: state.backgroundColor,
        color: state.color, // Add color
        border: state.border, // Add border
      }}
    >
      {state.text}
    </Tag>
  );
}

export default TagCol;
