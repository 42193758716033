import ReactDOM from 'react-dom/client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Button, Typography, Avatar, Grid, Stack } from '@mui/material';
import Loaderimage from '../../assets/images/loader.gif';
import SearchingImage from '../../assets/images/finding.gif';
import UserImage from '../../assets/images/user_image.jpg';
import TextareaAutosize from 'react-textarea-autosize';
import { SendSharp, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

const fakerData = {
  title: '',
  image: <UserImage />,
  drafts: [
    {
      prompt:
        'Here\'s an example of a SVG icon for a social media app:\n\n```xml\n<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">\n  <path d="M23 2C22.04 6.5 18.1 11 12 11C5.92 11 2 6.5 1 2C1 2 3 2 12 2C21 2 23 2 23 2Z"></path>\n  <path d="M12 22C8.24 22 6 19.76 6 16C6 12.24 8.24 10 12 10C15.76 10 18 12.24 18 16C18 19.76 15.76 22 12 22Z"></path>\n  <path d="M12 10V22"></path>\n</svg>\n```\n\nThis example represents a generic social media icon with two circles forming the outline of the logo. Feel free to customize and modify this icon to fit the specific style and branding of your social media app.',
    },
    {
      prompt:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
    },
    {
      prompt:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
    },
  ],
  selected: '',
  selectedindex: '',
};

function Chat() {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState('');
  const [data, setData] = useState([]);
  const [draft, setDraft] = useState('');
  const [loading, setLoading] = useState(false);
  const [showdraft, setshowDraft] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 140);
  const [contentheight, setContentHeight] = useState(window.innerHeight - 300);

  // ENABE THIS TO HANDLE RESIZE OF WINDOW.
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight - 140);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResizecontent = () => {
      setContentHeight(window.innerHeight - 300);
    };
    window.addEventListener('resize', handleResizecontent);
    return () => {
      window.removeEventListener('resize', handleResizecontent);
    };
  }, []);

  const do_action = async () => {
    setLoading(true);
    await delay(2000);
    if (data.length > 0) {
      const newData = [...data, fakerData];
      const newDataWithUpdatedTitle = [...data];
      newDataWithUpdatedTitle.push({
        ...fakerData,
        title: prompt,
        selected: fakerData.drafts[0].prompt,
        selectedindex: 0,
      });
      setData(newDataWithUpdatedTitle);
    } else {
      const newData = [
        {
          ...fakerData,
          title: prompt,
          selected: fakerData.drafts[0].prompt,
          selectedindex: 0,
        },
      ];
      setData(newData);
    }
    setPrompt('');
    setLoading(false);
  };

  const reset_prompt = async () => {
    if (data.length > 0) {
      // const newData = [...data, fakerData];
      // const newDataWithUpdatedTitle = [...data];
      // newDataWithUpdatedTitle.push({ ...fakerData, title: prompt, selected: fakerData.drafts[0].prompt, selectedindex:0 });
      setData([]);
    }
    setPrompt('');
  };

  const do_show_draft = async (contenttt, iiii, vi) => {
    const updatedData = [...data];
    updatedData[iiii].selected = contenttt;
    updatedData[iiii].selectedindex = vi;
    setData(updatedData);
  };

  return (
    <div className={'content row'}>
      <Card
        variant='text'
        className={'col-11 col-lg-8 m-auto'}
        sx={{
          minWidth: 350,
          background: '#f0f0ef',
          borderRadius: '20px',
          height: windowHeight,
        }}
      >
        <CardContent
          sx={{
            height: contentheight,
            overflow: 'auto',
          }}
        >
          {data.length > 0 && (
            <>
              {data.map((v, i) => (
                <>
                  <Box key={'prompt_' + i} sx={{ mb: 3 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        mb: 2,
                        gap: '15px',
                        alignItems: 'top',
                      }}
                    >
                      <Box>
                        <Avatar src={v.image.type} sx={{ width: 36, height: 36 }} />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: '#000000',
                          whiteSpace: 'pre-line',
                        }}
                        color='text.secondary'
                        gutterBottom
                      >
                        {v.title}
                      </Typography>
                    </Box>
                    <Card
                      variant='text'
                      sx={{
                        background: '#ffffff',
                        borderRadius: '16px',
                        padding: 3,
                        mb: 1,
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'top',
                        position: 'relative',
                      }}
                    >
                      {i === data.length - 1 && (
                        <Button
                          sx={{
                            mb: 1,
                            float: 'right',
                            position: 'absolute',
                            right: 10,
                            top: 5,
                            zIndex: 1,
                            textTransform: 'capitalize',
                          }}
                          endIcon={showdraft ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          onClick={() => setshowDraft(!showdraft)}
                        >
                          <Typography
                            sx={{
                              color: '#000',
                              fontSize: '11px',
                              float: 'left',
                            }}
                          >
                            View Other Drafts
                          </Typography>
                        </Button>
                      )}

                      <Box sx={{ width: '100%' }}>
                        {showdraft && i === data.length - 1 && (
                          <Box
                            sx={{
                              mb: 1,
                              mt: 3,
                              display: 'flex',
                              gap: '20px',
                            }}
                          >
                            <Box sx={{ width: '30px' }}></Box>

                            <Grid container>
                              {v.drafts.map((vv, iii) => (
                                <Grid
                                  key={`draft-${iii}`}
                                  xs={12}
                                  md={3.8}
                                  sx={{ mr: 1, marginBottom: '10px' }}
                                  onClick={async () => {
                                    await delay(500);
                                    do_show_draft(vv.prompt, i, iii);
                                  }}
                                >
                                  <Box
                                    sx={{
                                      background: iii == v.selectedindex ? '#f3f6fc' : 'transparent',
                                      padding: '10px',
                                      borderRadius: '10px',
                                      border: '1px solid #f0f0f0',
                                      height: '80px',
                                      overflow: 'hidden',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: '100%',
                                        marginBottom: '7px',
                                        float: 'left',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: iii == v.selectedindex ? '#ffffff' : '#00000',
                                          fontSize: '10px',
                                          background: iii == v.selectedindex ? '#0b57d0' : '#f3f6fc',
                                          borderRadius: 10,
                                          padding: '5px 15px',
                                          float: 'left',
                                          lineHeight: 1,
                                        }}
                                      >
                                        Draft {iii + 1}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        color: '#000',
                                        fontSize: '11px',
                                        float: 'left',
                                      }}
                                    >
                                      {vv.prompt}
                                    </Typography>
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        )}

                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            gap: '20px',
                            clear: 'both',
                            mt: !showdraft && i === data.length - 1 ? 2 : 0,
                          }}
                        >
                          <Box>
                            <img alt='loader' src={Loaderimage} width='30' />
                          </Box>
                          <Typography sx={{ fontSize: 14, color: '#000' }} color='text.secondary' gutterBottom>
                            {v.selected}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </>
              ))}
            </>
          )}
          {loading && (
            <Card
              variant='text'
              sx={{
                background: '#ffffff',
                borderRadius: '16px',
                padding: 2,
                mb: 1,
                display: 'flex',
                gap: '20px',
                alignItems: 'top',
              }}
            >
              <Box>
                <Avatar src={SearchingImage} sx={{ width: 32, height: 32 }} />
              </Box>
            </Card>
          )}
          {data.length == 0 && !loading && (
            <Card
              variant='text'
              sx={{
                background: '#ffffff',
                borderRadius: '16px',
                padding: 3,
                mb: 1,
                display: 'flex',
                gap: '20px',
                alignItems: 'top',
                boxSizing: 'border-box',
              }}
            >
              <Box>
                <img alt='loader' src={Loaderimage} width='30' />
              </Box>
              <Box>
                <Typography sx={{ fontSize: 16, color: '#000000' }} color='text.secondary' gutterBottom>
                  I’m Bard, your creative and helpful collaborator. I have limitations and won’t always get it right,
                  but your feedback will help me improve.
                </Typography>
                <Typography sx={{ fontSize: 16, mt: 2, color: '#000000' }} color='text.secondary' gutterBottom>
                  Not sure where to start? You can try:
                </Typography>
              </Box>
            </Card>
          )}
        </CardContent>

        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px' }}>
            <Stack spacing={2} direction='row'>
              <Button style={{ borderColor: '#7d7d7d', color: '#7d7d7d' }} variant='outlined'>
                Share Publicly
              </Button>
              <Button style={{ borderColor: '#7d7d7d', color: '#7d7d7d' }} variant='outlined'>
                Share Privatly
              </Button>
            </Stack>
          </Box>
          <Box
            sx={{
              pb: 0,
              display: 'flex',
              alignItems: 'center',
              width: '90%',
              margin: 'auto',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                pb: 0,
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <TextareaAutosize
                  autoFocus
                  style={{
                    width: '90%',
                    marginLeft: 'auto',
                    borderRadius: '50px',
                    border: '1px solid #13131460',
                    padding: '20px 30px',
                    fontFamily: 'Roboto, sans-serif',
                    height: '20px',
                    fontSize: '15px',
                    fontWeight: '500',
                    resize: 'none',
                    background: '#ffffff',
                    color: '#000000',
                    overflow: 'hidden',
                    outline: '1px solid #202020',
                  }}
                  placeholder='Enter a prompt here'
                  maxRows={3}
                  value={prompt}
                  onChange={(e) => {
                    if (e.target.value == '') {
                      const old_height = window.innerHeight - 225;
                      setContentHeight(old_height + 0);
                    } else {
                      const textarea = e.target;
                      const rows = textarea.value.split('\n').length;
                      if (rows > 1 && rows <= 3) {
                        const newHeight = 12 * rows;
                        const oldHeight = window.innerHeight - 225;
                        setContentHeight(oldHeight - newHeight);
                      }
                    }
                    setPrompt(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13 && e.shiftKey == false) {
                      do_action();
                    }
                  }}
                />
              </Box>
              <Box>
                <Stack direction='row'>
                  <Button
                    onClick={() => {
                      do_action();
                    }}
                    sx={{ borderRadius: '10%', width: '24px' }}
                    disabled={prompt.length == 0}
                  >
                    <SendSharp color={prompt.length == 0 ? 'disabled' : 'primary'} />
                  </Button>

                  {/* <Button
                    onClick={() => {
                      reset_prompt();
                    }}
                    disabled={data.length == 0}
                    variant='outlined'
                  >
                    Reset
                  </Button> */}
                </Stack>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  color: '#999999',
                  fontSize: '12px',
                  mt: '5px',
                }}
              >
                aiarchives may display inaccurate or offensive information that doesn’t represent aiarchives’s views.
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
export default Chat;
