import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import Fuse from 'fuse.js';

function UserAutoComplete() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [fuse, setFuse] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`https://aiarchives-375517.uc.r.appspot.com/api/search/`, { method: 'GET' });
        const data = await response.json();
        const transformedData = data.posts.map((item) => ({ title: item.title, postId: item.postId, model: item.model }));
        setFuse(new Fuse(transformedData, {
          shouldSort: true,
          minMatchCharLength: 3,
          includeScore: true,
          threshold: .2,
          ignoreLocation: true,
          keys: ['title']
        }));
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPosts();
  }, []);

  const handleSelection = (event, newValue) => {
    setSelectedOption(newValue);
    if (newValue) {
      window.open(`/id/${newValue.postId.trim()}`, '_blank');
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (newInputValue) {
      const results = fuse.search(newInputValue);
      results.sort((a, b) => a.score - b.score);
      setSearchResults(results.filter(({ score }) => score < 0.3).map(({ item }) => ({ item, inputValue: newInputValue })));
    } else {
      setSearchResults([]);
    }
  };

  const highlightMatch = (str, inputValue) => {
    const regex = new RegExp(`(${inputValue})`, 'gi');
    const match = str.match(regex);

    if (match) {
      const matchIndex = str.indexOf(match[0]);
      const matchEndIndex = matchIndex + inputValue.length;
      let start = matchIndex - Math.floor((150 - inputValue.length) / 2);
      let end = matchEndIndex + Math.ceil((150 - inputValue.length) / 2);

      if (start < 0) {
        end -= start;
        start = 0;
      }

      if (end > str.length) {
        start -= (end - str.length);
        end = str.length;
      }

      let displayTitle = str;

      if (str.length > 150) {
        displayTitle = `${start > 0 ? '...' : ''}${str.slice(start, end)}${end < str.length ? '...' : ''}`;
      }

      const highlighted = displayTitle.replace(regex, '<b>$1</b>');

      return { highlighted, matchIndex };
    } else {
      return { highlighted: str, matchIndex: -1 };
    }
  };


  return (
    <Box sx={{ p: 3, display: 'flex', marginTop: '50px' }}>
      <Autocomplete
        value={selectedOption}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelection}
        options={searchResults.map(({ item }) => item)}
        fullWidth
        sx={{ width: '100%' }}
        noOptionsText={inputValue === ''
          ? <span style={{ fontStyle: 'italic' }}>Type in a keyword</span>
          : <span style={{ fontStyle: 'italic' }}>No matching keywords</span>
        }
        isOptionEqualToValue={(option, value) => option.postId === value.postId}
        getOptionLabel={(option) => option.title.length > 200 ? option.title.slice(0, 197) + '...' : option.title}
        renderInput={(params) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              {...params}
              label="Search by Prompts"
              sx={{
                color: '#6B1E1E',
                '& label.Mui-focused': {
                  color: '#6B1E1E',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#6B1E1E',
                },
              }}
            />
          </Box>
        )}
        renderOption={(props, option, { inputValue }) => {
          const { highlighted: highlightedTitle, matchIndex } = highlightMatch(option.title, inputValue);
          const abbreviatedPostId = option.postId.substring(0, 8).padEnd(8);
          let backgroundColor;
          let color = '#ffffff'; // default text color
          let outline;

          switch (option.model) {
            case 'ChatGPT-4':
              backgroundColor = '#000000';
              break;
            case 'Bard':
              backgroundColor = '#f54842';
              break;
            case 'Claude':
              backgroundColor = '#ffffff';
              color = '#000000';
              outline = '1px solid #000000';
              break;
            case 'Meta':
              backgroundColor = '#4267B2';
              color = '#ffffff';
              break;
            default:
              backgroundColor = '#213860';
          }

          return (
            <li {...props} key={option.postId}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{
                  marginRight: '10px',
                  fontWeight: 'bold',
                  fontFamily: 'monospace',
                  backgroundColor: backgroundColor,
                  color: color,
                  borderRadius: '5px',
                  padding: '2px 4px',
                  outline: outline,
                }}>
                  {abbreviatedPostId}
                </div>
                <div dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
              </div>
            </li>
          );

        }}
      />
    </Box>
  );
}

export default UserAutoComplete;
