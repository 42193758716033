import {
  ADD_RESUME,
  SET_LOADING,
  SET_VALID_CODE,
  SET_SUBMIT_MODAL,
  SET_COMPANY,
  SET_SUBMIT_MODAL_SUCCESS,
  SET_SUBMIT_MODAL_WAIT,
} from '../types';

const initialState = {
  company: 'Any',
  showSubmitModal: false,
  loading: false,
  validCode: null,
  success: false,
  resumeAdded: false,
  needToWait: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESUME:
      return {
        ...state,
        resumeAdded: action.payload,
        loading: false,
      };
    case SET_VALID_CODE:
      return {
        ...state,
        validCode: action.payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_SUBMIT_MODAL:
      return {
        ...state,
        showSubmitModal: action.payload,
      };
    case SET_SUBMIT_MODAL_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case SET_SUBMIT_MODAL_WAIT:
      return {
        ...state,
        needToWait: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
