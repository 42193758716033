import { combineReducers } from 'redux';
import submitResume from './submitResume';
import verifyEmail from './emailVerify';
import layout from './layout';
import user from './user';

export default combineReducers({
  submitResume: submitResume,
  verifyEmail: verifyEmail,
  layout: layout,
  user: user,
});
