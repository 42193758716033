import { SET_USER_LOGOUT_SUCCESS, SET_USER_LOGIN_SUCCESS } from '../types';

const initialState = {
  profile: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOGIN_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_USER_LOGOUT_SUCCESS:
      return {
        ...state,
        profile: null,
      };
    default:
      return state;
  }
};
