import React from 'react';
import Lottie from 'react-lottie';
import * as postSuccessAnimationJson from '../../assets/message-sent-successfully-plane.json';
import * as loadingAnimationJson from '../../assets/loading.json';
import * as fileFolderAnimationJson from '../../assets/filing-files.json';

const postSuccessAnimation = {
  loop: true,
  autoplay: true,
  animationData: fileFolderAnimationJson.default,
};

const loadingAnimation = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimationJson.default,
};

export const PostSuccessAnimation = () => (
  <Lottie
    options={postSuccessAnimation}
    style={{ position: 'relative', top: '-20px' }}
    height={250}
    width={250}
  />
);

export const LoadingAnimation = () => (
  <Lottie
    options={loadingAnimation}
    style={{ position: 'relative', top: '-20px' }}
    height={250}
    width={250}
  />
);
