import './BottomButtons.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { getDoc, updateDoc, arrayUnion, doc } from 'firebase/firestore';
import { db } from '../../firestore.js';
import Modal from 'react-bootstrap/Modal';
import Alert from '@mui/material/Alert';

function BottomButtons() {
  const bookmarkedIconPath = 'M19 24l-7-6-7 6v-24h14v24z';
  const unbookmarkedIconPath =
    'M5 0v24l7-6 7 6v-24h-14zm1 1h12v20.827l-6-5.144-6 5.144v-20.827z';
  const bookmarkedColor = 'black';
  const unbookmarkedColor = '#f0f0ef';

  const [profile, setProfile] = useState(false);
  const [bookmark, setBookmark] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      if (user) {
        setProfile({ uid: user.uid });
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  useEffect(() => {
    if (profile?.uid) getBookmarks();
  }, [profile]);

  const getBookmarks = () => {
    fetch(
      `https://aiarchives-375517.uc.r.appspot.com/api/getSavedPosts/${profile?.uid}/${id}`,
      {
        method: 'GET',
      }
    ).then(async (res) => {
      const response = await res.json();
      response.savedPosts.forEach((savedID) => {
        if (savedID === id) setBookmark(true);
      });
    });
  };

  const bookmarkPost = () => {
    if (!profile) {
      window.alert(
        'You need to Login on the top right and have shared a post to save!'
      );
    } else {
      fetch(
        `https://aiarchives-375517.uc.r.appspot.com/api/toggleSave/${profile?.uid}/${id}`,
        {
          method: 'POST',
        }
      );
      setBookmark((bookmark) => !bookmark);
    }
  };

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(true);
    e.target.focus();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const [show, setShowCitation] = useState(false);
  const [citationFields, setCitationFields] = useState({});
  const handleCloseCitation = () => setShowCitation(false);
  const handleShowCitation = () => {
    setShowCitation((prevShowCitation) => {
      // If the modal was previously showing, we close it, and vice versa
      const nextShowCitation = !prevShowCitation;

      // Only fetch the citation fields if we're about to show the modal
      if (nextShowCitation) {
        getCitationFields().then((citationFields) => {
          // Check if the tags field is equal to "Bard" and change it to "Gemini"
          if (String(citationFields.tags) === 'Bard') {
            console.log('got here');
            citationFields.tags = 'Gemini';
          }
          console.log(typeof(citationFields.tags))
          setCitationFields(citationFields);
        });
      }

      return nextShowCitation;
    });
  };

  const getCitationFields = () => {
    const collectionName = id.length === 22 ? 'privatePosts' : 'posts';
    const docRef = doc(db, collectionName, id);

    return getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const citationFields = {
            title: docSnap
              .data()
              .title.trim()
              .replace(/\n/g, '')
              .substring(0, 20),
            id: docSnap.data().postId,
            tags: docSnap.data().tags,
            createdAPA: formatDateAPA(String(docSnap.data().created)),
            createdMLA: formatDateMLA(String(docSnap.data().created)),
            createdChicago: formatDateChicago(String(docSnap.data().created)),
          };
          if (docSnap.data().title.length > 20) {
            citationFields.title = citationFields.title.slice(0, -3) + '...';
          }
          return citationFields;
        } else {
          console.log('No such document!');
          return null;
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
        return null;
      });
  };


  const formatDateAPA = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    const date = new Date(year, month - 1, day);

    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    const formattedDate = date.toLocaleDateString('en-US', options);

    // Split the formattedDate string into an array: ["Month", "Day,", "Year"]
    const dateParts = formattedDate.split(' ');

    // Rearrange the dateParts array to: ["Year,", "Month", "Day"]
    const rearrangedDate = [
      dateParts[2] + ',',
      dateParts[0],
      dateParts[1].replace(',', ''),
    ]; // Add comma to the year

    // Join the rearrangedDate array into a string: "Year, Month Day"
    return rearrangedDate.join(' ');
  };

  const formatDateMLA = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10); // Convert to number
    const day = dateString.substring(6, 8);

    const date = new Date(year, month - 1, day);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const formattedDate = date.toLocaleDateString('en-US', options);

    const dateParts = formattedDate.split(' ');
    const rearrangedDate = [
      dateParts[1].split(',')[0],
      dateParts[0],
      dateParts[2],
    ];
    return rearrangedDate.join(' ');
  };

  const formatDateChicago = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10); // Convert to number
    const day = dateString.substring(6, 8);

    const date = new Date(year, month - 1, day);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  };


  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          URL copied!
        </Alert>
      </Snackbar>
      <div className='buttonContainer'>
        <button
          className='styledButton'
          onClick={copyToClipboard}
          style={{ borderRadius: '4px 0 0 4px' }}
        >
          <div className='buttonContent'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill-rule='evenodd'
              clip-rule='evenodd'
            >
              <path
                fill='#f0f0ef'
                d='M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z'
              />
            </svg>
            Link
          </div>
        </button>
        <button className='styledButton' onClick={bookmarkPost}>
          <div className='buttonContent'>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'>
              <path
                d={bookmark ? bookmarkedIconPath : unbookmarkedIconPath}
                fill={bookmark ? bookmarkedColor : unbookmarkedColor}
              />
            </svg>
            {bookmark ? 'Saved' : 'Save'}
          </div>
        </button>

        <button
          className='styledButton'
          onClick={handleShowCitation}
          style={{ borderRadius: '0 4px 4px 0' }}
        >
          <div className='buttonContent'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill-rule='evenodd'
              clip-rule='evenodd'
            >
              <path
                fill='#f0f0ef'
                d='M15 21c5.252-1.039 9-4.905 9-10.609v-7.391h-9.983v10h3.983c0 2.211-1.563 4.932-3.996 5.849l.996 2.151zm-14 0c5.252-1.039 8.983-4.905 8.983-10.609v-7.391h-9.983v10h4c0 2.211-1.563 4.932-3.995 5.849l.995 2.151zm.567-1.156l-.241-.52c2.394-1.391 3.674-4.159 3.674-6.324v-1h-4v-8h7.983v6.391c0 4.687-2.82 8.248-7.416 9.453m14 .001l-.241-.521c2.394-1.391 3.674-4.159 3.674-6.324v-1h-3.983v-8h7.983v6.391c0 4.686-2.827 8.247-7.433 9.454'
              />
            </svg>
            Cite
          </div>
        </button>
        <Modal
          style={{ width: '100vw' }}
          show={show}
          onHide={handleCloseCitation}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className='font-weight-bold'>Citations</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ whiteSpace: 'pre-wrap' }}>
            <strong style={{ marginLeft: '10px' }}>Chicago Manual of Style:</strong>
            {'\n'}
            <span style={{ marginLeft: '20px' }}>
              {citationFields.tags}, response to “{citationFields.title},” {citationFields.createdChicago},{'\n'}
              <span style={{ marginLeft: '20px' }}>
                https://aiarchives.org/id/{id}
              </span>
            </span>
            {'\n\n'}
            <strong style={{ marginLeft: '10px' }}>APA:</strong>
            {'\n'}
            <span style={{ marginLeft: '20px' }}>
              {citationFields.tags}. ({citationFields.createdAPA}).{' '}
              <em>{citationFields.title}</em> A.I. Archives.{'\n'}
              <span style={{ marginLeft: '20px' }}>
                https://aiarchives.org/id/{id}
              </span>
            </span>
            {'\n\n'}
            <strong style={{ marginLeft: '10px' }}>MLA:</strong>
            {'\n'}
            <span style={{ marginLeft: '20px' }}>
              {citationFields.tags}. "{citationFields.title}"{' '}
              <em>A.I. Archives,</em> {citationFields.createdMLA},{'\n'}
              <span style={{ marginLeft: '20px' }}>
                https://aiarchives.org/id/{id}
              </span>
            </span>
          </Modal.Body>
          <div style={{ height: '25px' }} />
        </Modal>

      </div>
    </div>
  );
}

export default BottomButtons;
