import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../firestore';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const [profile, setProfile] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      if (user) {
        const redirectURL = localStorage.getItem('loginRedirect');
        if (redirectURL) {
          navigate(redirectURL);
          localStorage.removeItem('loginRedirect');
        }
        setProfile({ uid: user.uid });
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);
  if (!profile) {
    // user is not authenticated
    return <Navigate to='/' />;
  }
  return children;
};

export default ProtectedRoute;
