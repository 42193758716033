import { INIT_EMAIL_VERIFIED, SET_EMAIL_VERIFIED } from '../types';

export const verifyEmail = (email, id) => {
  return {
    type: INIT_EMAIL_VERIFIED,
    email: email,
    id: id,
  };
};

export const verifyEmailSuccess = (res) => {
  return {
    type: SET_EMAIL_VERIFIED,
    payload: true,
  };
};

export const verifyEmailFailed = (res) => {
  return {
    type: SET_EMAIL_VERIFIED,
    payload: false,
  };
};
