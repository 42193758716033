export const BoxIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1024 1024'
      className='w-12'
    >
      <path
        d='M0 0H1024V1024H0z'
        className='transition-all duration-300 fill-zinc-900 dark:fill-zinc-300'
      ></path>
      <svg className='transition-all duration-300 fill-zinc-50 dark:fill-zinc-900'>
        <circle cx='512.408' cy='511.592' r='65.236'></circle>
        <circle cx='512.408' cy='731.764' r='65.236'></circle>
        <circle cx='512.408' cy='292.236' r='65.236'></circle>
        <circle cx='292.236' cy='511.592' r='65.236'></circle>
        <circle cx='292.236' cy='731.764' r='65.236'></circle>
        <circle cx='292.236' cy='292.236' r='65.236'></circle>
        <circle cx='732.579' cy='511.592' r='65.236'></circle>
        <circle cx='732.579' cy='731.764' r='65.236'></circle>
        <circle cx='732.579' cy='292.236' r='65.236'></circle>
      </svg>
    </svg>
  );
};

export const AppleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      ariaHidden='true'
      className='text-xs fa-apple fa-fw'
      data-icon='apple'
      data-prefix='fab'
      viewBox='0 0 384 512'
    >
      <path
        fill='currentColor'
        d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z'
      ></path>
    </svg>
  );
};

export const PuzzleIcon = () => {
  return (
    <svg
      viewBox="0 0 512 512"
      fill='#E6E6E5'
      height="16px"
      width="16px"
    >
      <path d="M192 104.8c0-9.2-5.8-17.3-13.2-22.8-11.6-8.7-18.8-20.7-18.8-34 0-26.5 28.7-48 64-48s64 21.5 64 48c0 13.3-7.2 25.3-18.8 34-7.4 5.5-13.2 13.6-13.2 22.8 0 12.8 10.4 23.2 23.2 23.2H336c26.5 0 48 21.5 48 48v56.8c0 12.8 10.4 23.2 23.2 23.2 9.2 0 17.3-5.8 22.8-13.2 8.7-11.6 20.7-18.8 34-18.8 26.5 0 48 28.7 48 64s-21.5 64-48 64c-13.3 0-25.3-7.2-34-18.8-5.5-7.4-13.6-13.2-22.8-13.2-12.8 0-23.2 10.4-23.2 23.2V464c0 26.5-21.5 48-48 48h-56.8c-12.8 0-23.2-10.4-23.2-23.2 0-9.2 5.8-17.3 13.2-22.8 11.6-8.7 18.8-20.7 18.8-34 0-26.5-28.7-48-64-48s-64 21.5-64 48c0 13.3 7.2 25.3 18.8 34 7.4 5.5 13.2 13.6 13.2 22.8 0 12.8-10.4 23.2-23.2 23.2H48c-26.5 0-48-21.5-48-48V343.2C0 330.4 10.4 320 23.2 320c9.2 0 17.3 5.8 22.8 13.2 8.7 11.6 20.7 18.8 34 18.8 26.5 0 48-28.7 48-64s-21.5-64-48-64c-13.3 0-25.3 7.2-34 18.8-5.5 7.4-13.6 13.2-22.8 13.2C10.4 256 0 245.6 0 232.8V176c0-26.5 21.5-48 48-48h120.8c12.8 0 23.2-10.4 23.2-23.2z" />
    </svg>
  );
};

export const TwitterIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      ariaHidden='true'
      className='text-xs fa-twitter fa-fw'
      data-icon='twitter'
      data-prefix='fab'
      viewBox='0 0 512 512'
    >
      <path
        fill='currentColor'
        d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'
      ></path>
    </svg>
  );
};

export const DiscordIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      ariaHidden='true'
      className='text-xs fa-discord fa-fw'
      data-icon='discord'
      data-prefix='fab'
      viewBox='0 0 640 512'
    >
      <path
        fill='currentColor'
        d='M524.531 69.836a1.5 1.5 0 00-.764-.7A485.065 485.065 0 00404.081 32.03a1.816 1.816 0 00-1.923.91 337.461 337.461 0 00-14.9 30.6 447.848 447.848 0 00-134.426 0 309.541 309.541 0 00-15.135-30.6 1.89 1.89 0 00-1.924-.91 483.689 483.689 0 00-119.688 37.107 1.712 1.712 0 00-.788.676C39.068 183.651 18.186 294.69 28.43 404.354a2.016 2.016 0 00.765 1.375 487.666 487.666 0 00146.825 74.189 1.9 1.9 0 002.063-.676A348.2 348.2 0 00208.12 430.4a1.86 1.86 0 00-1.019-2.588 321.173 321.173 0 01-45.868-21.853 1.885 1.885 0 01-.185-3.126 251.047 251.047 0 009.109-7.137 1.819 1.819 0 011.9-.256c96.229 43.917 200.41 43.917 295.5 0a1.812 1.812 0 011.924.233 234.533 234.533 0 009.132 7.16 1.884 1.884 0 01-.162 3.126 301.407 301.407 0 01-45.89 21.83 1.875 1.875 0 00-1 2.611 391.055 391.055 0 0030.014 48.815 1.864 1.864 0 002.063.7A486.048 486.048 0 00610.7 405.729a1.882 1.882 0 00.765-1.352c12.264-126.783-20.532-236.912-86.934-334.541zM222.491 337.58c-28.972 0-52.844-26.587-52.844-59.239s23.409-59.241 52.844-59.241c29.665 0 53.306 26.82 52.843 59.239 0 32.654-23.41 59.241-52.843 59.241zm195.38 0c-28.971 0-52.843-26.587-52.843-59.239s23.409-59.241 52.843-59.241c29.667 0 53.307 26.82 52.844 59.239 0 32.654-23.177 59.241-52.844 59.241z'
      ></path>
    </svg>
  );
};

export const QuestionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#E6E6E5" width="17px" height="17px" viewBox="0 0 1920 1920">
    <path d="M960 1242.342c62.23 0 112.941 50.71 112.941 112.94 0 62.231-50.71 112.942-112.941 112.942-62.23 0-112.941-50.71-112.941-112.941 0-62.23 50.71-112.941 112.941-112.941Zm89.336-892.01c114.862 29.704 208.264 123.106 237.968 237.967 23.378 90.466 10.729 183.304-35.464 261.46-45.515 77.138-121.186 133.947-207.586 156.084-13.779 3.614-27.783 14.795-27.783 31.962v91.595H903.529v-91.595c0-66.183 46.306-124.235 112.716-141.29 57.6-14.795 107.971-52.743 138.353-104.131 30.833-52.292 39.19-114.635 23.378-175.85-19.651-75.67-81.204-137.223-156.875-156.875-70.927-18.183-143.435-3.953-199.341 39.304-55.68 43.143-87.642 108.31-87.642 178.673H621.176c0-105.6 47.888-203.294 131.464-268.01 83.69-64.828 191.774-86.287 296.696-59.294ZM960-.01c-529.355 0-960 430.644-960 960 0 529.355 430.645 960 960 960 529.468 0 960-430.645 960-960 0-529.356-430.532-960-960-960" fill-rule="evenodd"/>
</svg>
  );
};

export const LoginIcon = () => {
  return (
    <svg viewBox='0 0 24 24' fill='currentColor' height='19px' width='19px'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path d='M10 11H4V3a1 1 0 011-1h14a1 1 0 011 1v18a1 1 0 01-1 1H5a1 1 0 01-1-1v-8h6v3l5-4-5-4v3z' />
    </svg>
  );
};

export const DasboardIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      ariaHidden='true'
      fill='#E6E6E5'
      height='17px'
      width='17px'
    >
      <path fill='none' d='M0 0h24v24H0z' />
      <path d='M13 3v6h8V3m-8 18h8V11h-8M3 21h8v-6H3m0-2h8V3H3v10z' />
    </svg>
  );
};

export const MagnifyingGlassIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="19px" height="19px" fill='#E6E6E5'>    <path d="M 7 4 C 5.35 4 4 5.35 4 7 L 4 43 C 4 44.65 5.35 46 7 46 L 43 46 C 44.65 46 46 44.65 46 43 L 46 7 C 46 5.35 44.65 4 43 4 L 7 4 z M 22.5 13 C 27.74 13 32 17.26 32 22.5 C 32 24.76 31.210625 26.840703 29.890625 28.470703 L 37.710938 36.289062 L 36.289062 37.710938 L 28.470703 29.890625 C 26.840703 31.210625 24.76 32 22.5 32 C 17.26 32 13 27.74 13 22.5 C 13 17.26 17.26 13 22.5 13 z M 22.5 15 A 7.5 7.5 0 0 0 15 22.5 A 7.5 7.5 0 0 0 22.5 30 A 7.5 7.5 0 0 0 30 22.5 A 7.5 7.5 0 0 0 22.5 15 z" /></svg>
  );
};


