import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../App.scss';
import aiarchiveslogo from '../../assets/images/aiarchiveslogo.png';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { verifyEmail } from '../../redux/actions/emailVerify';
import { Fragment } from 'react';

const SignIn = ({ verifyEmail, isValid }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();

  useEffect(() => {}, []);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/dashboard');
  };

  return (
    <Fragment>
      <div style={{ textAlign: 'center' }} className='col-3 col-md-5 col-xl-6'>
        <img
          src={aiarchiveslogo}
          style={{ width: '200px', margin: '50px 0px 20px' }}
          alt='logo'
        />
      </div>
      <div className='Login'>
        <Form onSubmit={handleSubmit}>
          <Form.Group size='lg' controlId='email'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              size='lg'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group size='lg' controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              size='lg'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button
            block
            size='lg'
            type='submit'
            color='primary'
            className='btn btn-secondary'
            disabled={!validateForm()}
          >
            Login
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

SignIn.prototype = {
  verifyEmail: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isValid: state.verifyEmail.isValid,
});

export default connect(mapStateToProps, { verifyEmail })(SignIn);
