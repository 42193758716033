import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import Tag from './Tag';
import '../../App.scss';

const Card = ({ title, id, view, tags, photoURL, views, created, relatedPosts, suggestedPosts }) => {
  function formatViews(views) {
    if (views >= 1000000) {
      return (views / 1000000).toFixed(1) + 'M';
    } else if (views >= 1000) {
      return (views / 1000).toFixed(1) + 'K';
    } else {
      return views;
    }
  }

  function getDaysBetweenDates(givenInteger) {
    const givenDate = new Date(
      Date.UTC(
        parseInt(givenInteger.toString().slice(0, 4)), // Year
        parseInt(givenInteger.toString().slice(4, 6)) - 1, // Month (zero-based)
        parseInt(givenInteger.toString().slice(6, 8)), // Day
        parseInt(givenInteger.toString().slice(8, 10)), // Hour
        parseInt(givenInteger.toString().slice(10, 12)), // Minute
        parseInt(givenInteger.toString().slice(12, 14)) // Second
      )
    );

    const currentDate = new Date();

    const differenceInMilliseconds = Math.abs(currentDate.getTime() - givenDate.getTime());
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInSeconds < 60) {
      return '<1 Min ago';
    } else if (differenceInMinutes === 1) {
      return differenceInMinutes + ' Min ago';
    } else if (differenceInMinutes < 60) {
      return differenceInMinutes + ' Mins ago';
    } else if (differenceInHours === 1) {
      return differenceInHours + ' Hr ago';
    } else if (differenceInHours < 24) {
      return differenceInHours + ' Hrs ago';
    } else if (differenceInDays === 1) {
      return differenceInDays + ' Day ago';
    } else {
      return differenceInDays + ' Days ago';
    }
  }

  function containsNonUsChars(s) {
    // All printable ASCII characters on a US keyboard.
    const usChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 `~!@#$%^&*()-_=+[{]}\\|;:\'",<.>/?';

    // Check if all characters in the input string are in the set of US keyboard characters.
    for (let i = 0; i < s.length; i++) {
      if (!usChars.includes(s[i])) {
        return true;
      }
    }
    return false;
  }

  function containsLongWord(s, maxLength) {
    return s.split(' ').some((word) => word.length > maxLength);
  }

  function truncate(str, n, useWordBoundary) {
    if (str.length <= n) {
      return str;
    }

    if (containsNonUsChars(str) && str.length >= 60) {
      n -= 25;
    }

    let subString = str.slice(0, n - 1); // the original check
    subString = subString.replace(/\s/g, ' ');

    return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '...';
  }

  const removeFirstNewLine = (str) => {
    return str.replace(/(\r\n|\n|\r)/g, '');
  };

  return (
    <Fragment>
      <div style={{ padding: '6px' }} className={`col-12 ${view ? '' : 'col-xl-6'}`}>
        <a
          href={`/id/${id}`}
          target='_blank'
          rel='noopener noreferrer'
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <div
            style={{
              margin: 'auto',
              cursor: 'pointer',
              height: '120px',
              position: 'relative',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              borderRadius: '5px',
            }}
            className='step-box'
          >
            <div className='row'>
              <h1
                className='col-12'
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  display: 'flex',
                  overflow: 'hidden', // Add this
                }}
              >
                <img
                  src={photoURL}
                  alt='Your description'
                  style={{
                    width: '26px',
                    height: '26px',
                    marginRight: '10px',
                    borderRadius: '80%',
                  }}
                />
                <div
                  style={{
                    minWidth: 0,
                    wordBreak: containsLongWord(truncate(removeFirstNewLine(title), 80, true), 15)
                      ? 'break-all'
                      : 'normal',
                  }}
                >
                  {truncate(removeFirstNewLine(title), 80, true)}
                </div>
              </h1>
            </div>
            <Tag postId={id} tags={tags} />
            <div
              className='stats'
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                marginRight: '20px',
                marginBottom: '5px',
              }}
            >
              <span className='views'>{formatViews(views)} Views |</span>
              <span className='date-created'>{getDaysBetweenDates(String(created))} |</span>
              <span className='date-created'>
                {Array.from(new Set([...relatedPosts, ...suggestedPosts])).length} Related
              </span>
            </div>
          </div>
        </a>
      </div>
    </Fragment>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  tags: PropTypes.array,
  view: PropTypes.bool,
};

export default Card;
