import React, { useEffect, useState, Fragment } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Dropdown } from 'react-bootstrap';
import TableList from '../../components/Table/Table';
import Button from 'react-bootstrap/Button';
import DashboardAutoComplete from '../../components/DashboardAutoComplete/DashboardAutoComplete';

const Dashboard = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [openMoveToDialog, setOpenMoveToDialog] = useState(false);
  const [manageFolder, setManageFolder] = useState(false);
  const [submission, setSubmission] = useState('posts');
  const [folders, setFolders] = useState([]);
  const [foldersChange, setFoldersChange] = useState([]);
  const [profile, setProfile] = useState(false);
  const handleChange = (event) => setSearchTerm(event.target.value);
  const submissions = (state) => setSubmission(state);

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      if (user) {
        setProfile({ uid: user.uid });
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  const getFolders = () => {
    if (profile?.uid) {
      fetch(`https://aiarchives-375517.uc.r.appspot.com/api/getFoldersFromUser/${profile.uid}`).then(async (res) => {
        const results = await res.json();
        setFolders(results?.folderNames);
      });
    }
  };

  useEffect(() => {
    if (profile?.uid || foldersChange) {
      getFolders();
    }
  }, [profile?.uid, foldersChange]);

  return (
    <Fragment>
      <Row className='align-items-center'>
        <Col md='8'>
          <Row className='mb-2 ml-1'>
            <Button
              variant='primary'
              onClick={() => submissions('posts')}
              className={submission === 'posts' ? 'sm outline:active ml-0 mr-4 mb-2' : 'sm outline ml-0 mr-4 mb-2'}
            >
              <span>My Posts</span>
            </Button>
            <Button
              variant='primary'
              onClick={() => submissions('bookmarked')}
              className={submission === 'bookmarked' ? 'sm outline:active ml-0 mr-4 mb-2' : 'sm outline ml-0 mr-4 mb-2'}
            >
              <span>Saved</span>
            </Button>
            <Button
              variant='primary'
              onClick={() => submissions('private')}
              className={submission === 'private' ? 'sm outline:active ml-0 mr-4 mb-2' : 'sm outline ml-0 mr-4 mb-2'}
            >
              <span>Private Posts</span>
            </Button>

            <Button
              variant='primary'
              onClick={() => {
                setOpenMoveToDialog(true);
                setManageFolder('all');
              }}
              eventkey='manageFolders'
              className={
                submission === 'manageFolders' ? 'sm outline ml-0 mr-4 mb-2' : 'sm outline:active ml-0 mr-4 mb-2'
              }
            >
              <span>Create/Edit Folders</span>
            </Button>
            <Dropdown style={{ width: 'auto', marginLeft: '-10px' }} className={'sm mr-4 mb-2'}>
              <Dropdown.Toggle style={{ height: '40px', fontSize: '18px' }} variant='primary' id='dropdown-basic'>
                Folders
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {folders.sort().map((folder, index) => (
                  <Dropdown.Item
                    key={index}
                    style={{
                      fontStyle: 'bold',
                      fontSize: '18px',
                      fontWeight: '500',
                      backgroundColor: folder === submission ? '#6b1e1e' : 'white',
                      color: folder === submission ? 'white' : 'black',
                    }}
                    onClick={() => submissions(folder)}
                    eventkey={index}
                  >
                    {folder}
                  </Dropdown.Item>
                ))}
                <Dropdown.Item
                  key={'My Posts'}
                  style={{
                    fontSize: '18px',
                    fontStyle: 'italic',
                    fontWeight: '500',
                    backgroundColor: 'posts' === submission ? '#6b1e1e' : 'white',
                    color: 'posts' === submission ? 'white' : 'black',
                  }}
                  onClick={() => submissions('posts')}
                  eventkey='My Posts'
                >
                  My Posts
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </Col>
        <Col md='4'>
          <DashboardAutoComplete profile={profile} />
        </Col>
      </Row>
      <TableList
        filter={submission}
        openMoveToDialog={openMoveToDialog}
        manageFolder={manageFolder}
        setManageFolder={setManageFolder}
        setOpenMoveToDialog={setOpenMoveToDialog}
        setFoldersChange={setFoldersChange}
      />
    </Fragment>
  );
};

export default connect()(Dashboard);
