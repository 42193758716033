import React from 'react';
import { AiFillHome } from 'react-icons/ai';

const AdminRoutes = [
  {
    path: '/',
    name: 'Home',
    icon: <AiFillHome />,
  },
];

export default AdminRoutes;
