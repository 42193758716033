import React from 'react';

const RecentIcon = () => {
  return (
    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium h-4 w-4 text-gray-600 css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CachedOutlinedIcon">
        <path d="m19 8-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z">
        </path>
    </svg>
  );
};
export default RecentIcon;