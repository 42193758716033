import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';

const StarRating = ({ postId, uid }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [displayRating, setDisplayRating] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  useEffect(() => {
    if (postId) {
      fetch(`https://aiarchives-375517.uc.r.appspot.com/api/getRating/${postId}`, { method: 'GET' })
        .then((response) => response.json())
        .then((data) => {
          if (data?.averageRating > 0) {
            setRating(data.averageRating);
            setHover(data.averageRating);
            setDisplayRating(data.averageRating);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [postId]);

  const handleMouseEnter = (value) => {
    setHover(value);
  };

  const handleMouseLeave = () => {
    setHover(rating);
  };

  const submitRate = (rate) => {
    if (uid) {
      fetch(`https://aiarchives-375517.uc.r.appspot.com/api/addRating/${postId}/${uid}/${rate}`, {
        method: 'POST',
      }).then((res) => {
        if (res.ok) {
          fetchUpdatedRating(); // Fetch the updated rating after successful submission
          setAlertOpen(true);
          setAlertSeverity('success');
          setAlertMessage('Your rating was successfully submitted.');
        } else {
          setAlertOpen(true);
          setAlertSeverity('error');
          setAlertMessage('There was an issue submitting your rating. Please try again.');
        }
      });
    } else {
      setAlertOpen(true);
      setAlertSeverity('error');
      setAlertMessage('You need to be logged in before submitting your rating.');
    }
  };

  const fetchUpdatedRating = () => {
    if (postId) {
      fetch(`https://aiarchives-375517.uc.r.appspot.com/api/getRating/${postId}`, { method: 'GET' })
        .then((response) => response.json())
        .then((data) => {
          if (data?.averageRating >= 0) { // Handle cases where the average rating might be exactly 0
            setRating(data.averageRating);
            setHover(data.averageRating);
            setDisplayRating(data.averageRating);
          }
        })
        .catch((error) => {
          console.error('Error fetching updated rating:', error);
        });
    }
  };

  return (
    <>
      <div className='flex flex-col items-start' style={{ margin: '30px' }}>
        <div className='flex'>
          {[...Array(5)].map((_, index) => {
            const starValue = index + 1;
            const isFullStar = starValue <= Math.floor(hover);
            const isPartialStar = starValue === Math.floor(hover) + 1 && hover % 1 > 0;
            return (
              <div
                key={index}
                className='relative w-8 h-8 cursor-pointer'
                onClick={() => submitRate(starValue)}
                onMouseEnter={() => handleMouseEnter(starValue)}
                onMouseLeave={handleMouseLeave}
              >
                <Star className='absolute inset-0 text-gray-300' fill='none' />
                {isFullStar && <Star className='absolute inset-0 text-yellow-400 fill-current' />}
                {isPartialStar && (
                  <Star
                    className='absolute inset-0 text-yellow-400 fill-current'
                    style={{ clipPath: `inset(0 ${100 - (hover % 1) * 100}% 0 0)` }}
                  />
                )}
              </div>
            );
          })}
        </div>
        <h2 className='mt-2 text-lg' style={{ marginLeft: '0px' }}>
          {displayRating ? `Users rated this ${displayRating.toFixed(1)} star${displayRating !== 1 ? 's' : ''}` : 'Rate this Conversation'}
        </h2>
      </div>
      <Snackbar
        style={{ zIndex: '999999999', width: '50%', bottom: '0px' }}
        open={alertOpen}
        autoHideDuration={2000}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          variant='filled'
          elevation={6}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StarRating;
