import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import { getDoc, doc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firestore.js';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { Button } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import CreateFolder from '../CreateFolder/CreateFolder.js';

const handleClick = (id) => {
  window.open(`https://aiarchives.org/id/${id}`, '_blank');
};

const useStyles = makeStyles({
  selectedRow: {
    backgroundColor: '#ef53501f !important', // change this to the color you want
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  function comp(a, b) {
    if (order === 'asc') {
      return descendingComparator(a, b, orderBy);
    } else {
      return -descendingComparator(a, b, orderBy);
    }
  }
  return comp;
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Title',
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: false,
    label: 'Model',
  },
  {
    id: 'views',
    numeric: true,
    disablePadding: false,
    label: 'Views',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Date',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: '1.2rem',
              fontFamily: 'Georgia',
              fontWeight: 'bold',
              color: '#2d2d2d',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected, handleMoveTo, tableTitle, isFolder, removeFromFolder } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.error.light, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
          {tableTitle}
        </Typography>
      )}
      {numSelected > 0 ? (
        isFolder ? (
          <IconButton type='button' onClick={removeFromFolder} style={{ borderRadius: '4px' }}>
            <Typography
              style={{
                fontSize: '1.2rem',
                fontFamily: 'Georgia',
                fontWeight: 'bold',
                color: '#2d2d2d',
              }}
            >
              Remove from the Folder
            </Typography>
          </IconButton>
        ) : (
          <IconButton type='button' onClick={handleMoveTo} style={{ borderRadius: '4px' }}>
            <Typography
              style={{
                fontSize: '1.2rem',
                fontFamily: 'Georgia',
                fontWeight: 'bold',
                color: '#2d2d2d',
              }}
            >
              Move to Folder
            </Typography>
          </IconButton>
        )
      ) : null}
    </Toolbar>
  );
}

const TableList = (props) => {
  const { openMoveToDialog, manageFolder, setManageFolder, setOpenMoveToDialog, setFoldersChange } = props;
  const [profile, setProfile] = useState(false);
  const [myposts, setMyPosts] = useState([]);
  const [bookmarked, setBookmarked] = useState([]);
  const [posts, setPosts] = useState([]);
  const [order, setOrder] = useState('asc');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [orderBy, setOrderBy] = useState('created');
  const [folderName, setFolderName] = useState('');
  const [folders, _setFolders] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addFolderDialog, setAddFolderDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableTitle, setTableTitle] = useState('My Posts');
  const [isFolder, setIsFolder] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const classes = useStyles();
  const [folderCount, setFolderCount] = useState(0);
  const [openFolderCountSnackbar, setFolderCounttOpenSnackbar] = useState(false);

  const setFolders = (newFolders) => {
    _setFolders(newFolders.sort());
  };

  function truncate(str, n, useWordBoundary) {
    if (str.length <= n) {
      return str;
    }
    const subString = str.slice(0, n - 1); // the original check
    return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '...';
  }

  function formatDate(timestamp) {
    var dd = timestamp.toString().slice(6, 8);
    var mm = timestamp.toString().slice(4, 6);
    var yy = timestamp.toString().slice(0, 4);
    return yy + '/' + mm + '/' + dd;
  }

  const handleCheck = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = posts.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFolderNameChange = (event) => {
    setFolderName(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMoveToDialog = () => {
    setOpenMoveToDialog((prevState) => !prevState);
    setManageFolder(false);
  };

  const handleAddToDialog = () => {
    setAddFolderDialog((prevState) => !prevState);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - posts.length) : 0;

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      if (user) {
        setProfile({ uid: user.uid });
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  useEffect(() => {
    if (profile?.uid) {
      const userRef = doc(db, 'users', profile.uid);
      getDoc(userRef).then((docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          let postIds = [];

          // Add post IDs from userData if available
          if (userData?.convPostMap) {
            postIds = postIds.concat(Object.values(userData.convPostMap));
          }
          if (userData?.savedPosts) {
            postIds = postIds.concat(userData.savedPosts);
          }

          const convPostMapV2Ref = collection(db, `users/${profile.uid}/convPostMap_v2`);
          getDocs(convPostMapV2Ref).then((snapshot) => {
            let v2PostIds = [];
            if (!snapshot.empty) {
              snapshot.forEach((doc) => {
                const data = doc.data();
                v2PostIds = v2PostIds.concat(Object.values(data));
              });
            }
            // Deduplicate postIds after all sources are fetched
            postIds = [...new Set([...postIds, ...v2PostIds])];

            console.log(postIds)

            // Fetch details of posts based on postIds
            const fetchPostPromises = postIds.map((postId) => {
              const docRef = postId?.length === 22 ? doc(db, 'privatePosts', postId) : doc(db, 'posts', postId);
              return getDoc(docRef).then((postSnap) => {
                if (postSnap.exists()) {
                  // const data = postSnap.data();
                  // Check if 'tags' array exists and has at least one element
                  // const modelTag = data.tags && data.tags.length > 0 ? data.tags[0] : 'No Model Tag';
                  // if (modelTag === 'No Model Tag') {
                  //   console.warn(`Missing 'tags[0]' for postId: ${postId}`, data); // Log for debugging
                  //   console.log(postSnap.data().title)
                  //   console.log(postSnap.data().postId)
                  // }
                  return {
                    title: postSnap.data().title,
                    views: postSnap.data().views,
                    model: postSnap.data()?.tags?.[0],
                    public: !postSnap.data().private,
                    created: postSnap.data().created,
                    id: postSnap.data().postId,
                  };
                }
                return null;
              });
            });

            Promise.all(fetchPostPromises).then((results) => {
              const validResults = results.filter((result) => result !== null); // Filter out null results
              const myPosts = validResults.filter((post) => postIds.includes(post.id)); // Adjust filter to include all collected post IDs
              const bookmarkedPosts = validResults.filter(
                (post) => userData.savedPosts && userData.savedPosts.includes(post.id)
              );
              setMyPosts(myPosts);
              setBookmarked(bookmarkedPosts);
            });
          });
        }
      });
    }
  }, [profile?.uid, db]);

  useEffect(() => {
    setPosts([]);
    setPage(0);
    setIsFolder(false);
    setSelected([]);
    if (props.filter === 'private') {
      const mypostsUnique = myposts.filter((mypost) => mypost.id?.length == 22);
      setPosts(mypostsUnique);
      setTableTitle('Private Posts');
    } else if (props.filter === 'bookmarked') {
      setPosts(bookmarked);
      setTableTitle('Saved');
    } else if (props.filter === 'posts') {
      setPosts(myposts);
      setTableTitle('My Posts');
    } else if (props.filter === 'leaderboard') {
      setTableTitle('Leaderboard');
    } else {
      getPostsFromFolder(props.filter);
      setIsFolder(true);
      setTableTitle(props.filter);
    }
    console.log('filter', props.filter);
  }, [props.filter, myposts, bookmarked]);

  useEffect(() => {
    if (isSmallScreen) {
      setDense(true);
    } else {
      setDense(false);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (profile?.uid) {
      getFolders();
    }
  }, [profile?.uid]);

  const handleFolderCountSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFolderCounttOpenSnackbar(false);
  };

  const getFolders = () => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/getFoldersFromUser/${profile?.uid}`).then(async (res) => {
      const results = await res.json();
      setFolders(results?.folderNames);
      setFolderCount(results?.folderNames.length);
    });
  };

  useEffect(() => {
    getFolders(); // this will initialize the folder count
  }, []);

  const getPostsFromFolder = (folder) => {
    if (folder) {
      fetch(`https://aiarchives-375517.uc.r.appspot.com/api/getPostsFromFolder/${profile?.uid}/${folder}`).then(
        async (res) => {
          const results = await res.json();
          const bookmarkedMap = bookmarked.map((post) => post.id);
          const mypostsUnique = myposts.filter((mypost) => !bookmarkedMap.includes(mypost.id));
          setPosts([...mypostsUnique, ...bookmarked].filter((mypost) => results.postIds.includes(mypost.id)));
        }
      );
    }
  };

  const removeFromFolder = () => {
    fetch(
      `https://aiarchives-375517.uc.r.appspot.com/api/deletePostToFolder/${profile?.uid}/${tableTitle}/${selected.join(
        '_'
      )}`,
      { method: 'POST' }
    ).then(async (res) => {
      setAlertOpen(true);
      setAlertMessage(`Posts removed from ${tableTitle} successfully!`);
      setSelected([]);
      setPosts(posts.filter((posts) => !selected.includes(posts.id)));
    });
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            handleMoveTo={handleMoveToDialog}
            tableTitle={tableTitle}
            isFolder={isFolder}
            removeFromFolder={removeFromFolder}
            filter={props.filter}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? 'small' : 'medium'}
              style={{ fontSize: '200px' }}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={posts.length}
                filter={props.filter}
              />
              <TableBody>
                {stableSort(posts, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((post, index) => {
                    const isItemSelected = isSelected(post.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={post.id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                        className={isItemSelected ? classes.selectedRow : ''}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            onClick={(event) => handleCheck(event, post.id)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          onClick={() => handleClick(post.id)}
                          style={{
                            fontSize: '1rem',
                            fontFamily: 'Georgia',
                            fontWeight: 'bold',
                            color: '#2d2d2d',
                          }}
                        >
                          {truncate(post.title, 140, true)}
                        </TableCell>
                        <TableCell
                          onClick={(event) => handleCheck(event, post.id)}
                          style={{
                            fontSize: '1rem',
                            fontFamily: 'Georgia',
                            color: '#2d2d2d',
                          }}
                          align='left'
                        >
                          {post.model === 'Bard' ? 'Gemini' : post.model}
                        </TableCell>
                        <TableCell
                          onClick={(event) => handleCheck(event, post.id)}
                          style={{
                            fontSize: '1rem',
                            fontFamily: 'Georgia',
                            color: '#2d2d2d',
                          }}
                          align='right'
                        >
                          {post.views}
                        </TableCell>
                        <TableCell
                          onClick={(event) => handleCheck(event, post.id)}
                          style={{
                            fontSize: '1rem',
                            fontFamily: 'Georgia',
                            color: '#2d2d2d',
                          }}
                          align='right'
                        >
                          {formatDate(post.created)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={posts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <CreateFolder
          openMoveToDialog={openMoveToDialog}
          manageFolder={manageFolder}
          setManageFolder={setManageFolder}
          setOpenMoveToDialog={setOpenMoveToDialog}
          setFoldersChange={setFoldersChange}
          selected={selected}
          setSelected={setSelected}
        />
      </Box>
    </>
  );
};

export default TableList;
