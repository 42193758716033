export const ADD_RESUME = 'ADD_RESUME';
export const SET_LOADING = 'SET_LOADING';
export const SET_VALID_CODE = 'SET_VALID_CODE';
export const SET_SUBMIT_MODAL = 'SET_SUBMIT_MODAL';
export const SET_SUBMIT_MODAL_SUCCESS = 'SET_SUBMIT_MODAL_SUCCESS';
export const SET_SUBMIT_MODAL_WAIT = 'SET_SUBMIT_MODAL_WAIT';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_NAVBAR = 'SET_NAVBAR';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';
export const INIT_EMAIL_VERIFIED = 'INIT_EMAIL_VERIFIED';
export const SET_USER_LOGIN_SUCCESS = 'SET_USER_LOGIN_SUCCESS';
export const SET_USER_LOGOUT_SUCCESS = 'SET_USER_LOGOUT_SUCCESS';
