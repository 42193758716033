import axios from 'axios';
import { verifyEmailSuccess, verifyEmailFailed } from '../actions/emailVerify';
import { put } from 'redux-saga/effects';

export function* verifyEmailSaga({ email, id }) {
  try {
    const response = yield axios.get(
      `https://revify-97268.uc.r.appspot.com/api/verification/${email}/${id}`
    );
    if (response.status === 200) {
      yield put(verifyEmailSuccess(response));
    }
  } catch (error) {
    if (error.response.status === 404) {
      yield put(verifyEmailFailed(error));
    }
  }
}
