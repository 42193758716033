import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import { watchEmailVerify } from './sagas';

const initialState = {};

const sagaMiddleWare = createSagaMiddleware();

const middleware = [thunk, sagaMiddleWare];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

sagaMiddleWare.run(watchEmailVerify);

export default store;
