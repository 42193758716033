import React from 'react';

const AnnouncementBanner = () => {
  return (
    <div className="bg-blue-50 border-l-4 border-blue-500 p-4 my-4">
      <h2 className="text-lg font-semibold text-blue-800">
        {"We've started rolling out an update to the extension. The 'share' buttons are now located directly within the extension. This change enhances the extension's resilience to website changes made by the AI hosts and improves visibility by ensuring the buttons don't block any chat text."}
      </h2>
      <div className="mt-2">
        <a
          href="https://www.youtube.com/watch?v=wwzEIJDeoPM"
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Check out the video
        </a>
      </div>
    </div>
  );
};

export default AnnouncementBanner;