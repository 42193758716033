import React, { useState, useEffect, useRef } from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { options } from './options';
import { Box, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

const _filterOptions = createFilterOptions();
const filterOption = (props, state) => {
  const results = _filterOptions(props, state);

  return results;
};

function OptionItem({ option, ...other }) {
  return (
    <li {...other} style={{ margin: '7px', padding: '0px' }}>
      <Chip
        label={option.tag}
        sx={{
          backgroundColor: '#254E70',
          color: '#f0f0ef',
          margin: '0px',
          padding: '0px',
          cursor: 'pointer', // Set the cursor to pointer
          '&:hover': {
            backgroundColor: '#6b1e1e',
          },
        }}
      />
    </li>
  );
}


export default function ConversationTag({
  profile,
  userId,
  postId,
  search,
  tags,
  setTags,
  inputBarWidth,
}) {
  const [inputFocused, setInputFocused] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [tagsFromAPI, setTagsFromAPI] = useState([]);
  // Add a state to indicate when API call is in progress
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  // Store the previous value of selectedOptions
  const prevSelectedOptionsRef = useRef();
  useEffect(() => {
    prevSelectedOptionsRef.current = selectedOptions;
  });

  useEffect(() => {
    if (tags) setSelectedOptions(tags);
  }, [tags]);

  // Reference the previous value
  const prevSelectedOptions = prevSelectedOptionsRef.current;

  // Fetch data from API when component mounts
  useEffect(() => {
    // Only fetch if postId is not null and no API call is in progress
    if (!search && postId && !apiCallInProgress) {
      fetch(
        `https://aiarchives-375517.uc.r.appspot.com/api/getTopicTagFromPost/${postId}`,
        { method: 'GET' }
      )
        .then((response) => response.json())
        .then((data) => {
          // Adjust this to match the actual data structure
          let transformedData = data.topicTag.map((item) => ({ tag: item }));
          setTagsFromAPI(transformedData);
          // Set the selectedOptions to the tags from API
          setSelectedOptions(transformedData);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [postId, apiCallInProgress]); // Depend on apiCallInProgress

  useEffect(() => {
    // If the input is not focused, or the selected options did not change, then do nothing
    if (
      search ||
      !inputFocused ||
      JSON.stringify(prevSelectedOptions) === JSON.stringify(selectedOptions)
    ) {
      return;
    }

    // Otherwise, convert the selected options to a comma-separated string and make the API call
    const tags = selectedOptions.map((option) => option.tag).join(',');

    setApiCallInProgress(true); // Indicate API call is in progress

    fetch(
      `https://aiarchives-375517.uc.r.appspot.com/api/setTopicTagFromPost/${postId}/${userId}/${tags}`,
      { method: 'POST' }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then(() => setApiCallInProgress(false)) // Indicate API call is done
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [inputFocused, selectedOptions, prevSelectedOptions, userId, postId]);

  const sortedOptions = [...options].sort((a, b) => a.tag.localeCompare(b.tag));

  return (
    <Box sx={{ p: 3, display: 'flex', marginTop: '50px' }}>
      <Autocomplete
        multiple
        value={selectedOptions}
        onChange={(event, newValue) => {
          newValue.sort((a, b) => a.tag.localeCompare(b.tag));
          if (newValue.length > 5) {
            setShowSnackbar(true);
          } else {
            if (setTags) setTags(newValue);
            else setSelectedOptions(newValue);
          }
        }}
        options={sortedOptions}
        fullWidth
        sx={{
          width: inputBarWidth,
          margin: '0 auto',
          // Add styles for hiding dropdown icon when disabled
          '& .MuiAutocomplete-popupIndicator': {
            display: profile === userId ? 'initial' : 'none',
            pointerEvents: profile === userId ? 'auto' : 'none', // Disabling pointer events when dropdown is disabled
          },
        }}
        ListboxProps={{
          style: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            maxHeight: '200px',
            overflowY: 'auto',
          },
        }}
        disableCloseOnSelect
        clearOnBlur
        clearIcon={<span />}
        filterOptions={filterOption}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.tag === value.tag}
        getOptionLabel={(option) => option.tag}
        renderOption={(props, option, { selected }) => (
          <OptionItem {...props} option={option} sx={{ color: '#254e70' }} />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <div
              key={index}
              onClick={
                profile !== userId
                  ? () =>
                      window.open(
                        `https://aiarchives.org/s/${option.tag}`,
                        '_blank'
                      )
                  : undefined
              }
              style={{
                cursor: profile !== userId || search ? 'pointer' : 'default',
              }} // Added this line
            >
            <Chip
            variant='outlined'
            label={option.tag}
            {...getTagProps({ index })}
            deleteIcon={inputFocused ? undefined : <span />}
            sx={{
              backgroundColor: '#6b1e1e',
              color: '#f0f0ef',
              '& .MuiChip-deleteIcon': {
                color: '#a42c2c',
              },
              '&.Mui-disabled': {
                opacity: 1, // override the default opacity
              },
              '&.Mui-disabled .MuiChip-label': {
                color: '#f0f0ef', // keep the color consistent when disabled
              },
              '&.Mui-disabled .MuiChip-deleteIcon': {
                color: '#a42c2c', // keep the deleteIcon color consistent when disabled
              },
              'span': {
                whiteSpace: 'nowrap',
                textOverflow: 'clip',
                overflow: 'hidden',
              }
            }}
          />
          
            </div>
          ))
        }
        disabled={!search && profile !== userId} // Disable the Autocomplete if the profile prop does not equal the userId prop
        renderInput={(params) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              {...params}
              label={
                selectedOptions.length > 1
                  ? 'Tags'
                  : selectedOptions.length === 1
                  ? 'Tag'
                  : search
                  ? 'Search by Tags'
                  : profile === userId
                  ? 'Add Some Tags!'
                  : 'No Tags, Login to Add, Must be Owner of Chat' // Change the label based on the condition
              }
              placeholder={
                !search
                  ? inputFocused &&
                    selectedOptions.length < 5 &&
                    profile === userId
                    ? 'Add More Tags...'
                    : ''
                  : 'Search by Tags'
              } // Only show the placeholder if the profile prop equals the userId prop
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              disabled={profile !== userId} // Disable the TextField if the profile prop does not equal the userId prop
              sx={{
                color: '#254e70',
                '& label.Mui-focused': {
                  color: '#254e70',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: '#254e70',
                  },
              }}
            />
          </Box>
        )}
      />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity='error'
          sx={{ width: '100%' }}
        >
          Maximum of 5 tags reached!
        </Alert>
      </Snackbar>
    </Box>
  );
}
