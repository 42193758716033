export const options = [
  ["Academic"],
  ["Accounting"],
  ["Advertisement"],
  ["AI"],
  ["Algebra"],
  ["Analysis"],
  ["Art"],
  ["Article"],
  ["Blog"],
  ["Brainstorming"],
  ["Business Ideas"],
  ["C#"],
  ["C++"],
  ["Calculus"],
  ["Career"],
  ["Career Advice"],
  ["Career Development"],
  ["ChatGPT Jailbreak"],
  ["Code"],
  ["Comedy"],
  ["Communication"],
  ["Comparisons"],
  ["Content Marketing"],
  ["Copywriting"],
  ["Creative"],
  ["Creative Writing"],
  ["Dating"],
  ["Database"],
  ["Diet"],
  ["Deep Learning"],
  ["Design"],
  ["Documentation"],
  ["Email"],
  ["Entrepreneurship"],
  ["Engineering"],
  ["Error"],
  ["Essay"],
  ["Excel"],
  ["Finance"],
  ["Food & Cooking"],
  ["Fun"],
  ["Funny"],
  ["Game"],
  ["Gaming"],
  ["GaTech"],
  ["Geometry"],
  ["Guide"],
  ["Gym"],
  ["Hackathon"],
  ["Health"],
  ["Hello World"],
  ["Idea Generation"],
  ["Image Generator"],
  ["Images"],
  ["Instagram"],
  ["Interesting"],
  ["Investment"],
  ["IT Support"],
  ["Jailbreak"],
  ["Java"],
  ["JavaScript"],
  ["Job"],
  ["Job Application"],
  ["Job Interview"],
  ["Job Search"],
  ["Language"],
  ["Law"],
  ["Learning"],
  ["LeetCode"],
  ["Lesson Plan"],
  ["Life Hacks"],
  ["Linkedin"],
  ["Literature"],
  ["Machine Learning"],
  ["Magic"],
  ["Marketing"],
  ["Mathematics"],
  ["Meal Prep"],
  ["Medical"],
  ["Mental Health"],
  ["Midjourney"],
  ["MIT"],
  ["Money"],
  ["Music"],
  ["MySQL"],
  ["New York"],
  ["Networking"],
  ["Nuclear Engineering"],
  ["Nuclear Physics"],
  ["Personal"],
  ["Philosophy"],
  ["Physics"],
  ["Poem"],
  ["Poker"],
  ["PowerPoint"],
  ["Problem Solving"],
  ["Product Management"],
  ["Productivity"],
  ["Professional Skills"],
  ["Programming"],
  ["Prompt Engineering"],
  ["Prompt Generator"],
  ["Psychology"],
  ["Python"],
  ["Quiz"],
  ["Quantum Mechanics"],
  ["Rankings"],
  ["React"],
  ["Religion"],
  ["Report Cards"],
  ["Research"],
  ["Resume Writing"],
  ["Roleplay"],
  ["RPG"],
  ["Ruby"],
  ["Rubric"],
  ["School"],
  ["Science"],
  ["Script"],
  ["SEO"],
  ["Simulation"],
  ["Social Media"],
  ["Software Development"],
  ["Songs"],
  ["Startup"],
  ["Statistics"],
  ["Stocks"],
  ["Storytelling"],
  ["Student"],
  ["Study"],
  ["Summary"],
  ["Teachers"],
  ["Template"],
  ["Text Generator"],
  ["Text-To-Image"],
  ["Therapy"],
  ["Tiktok"],
  ["Tools"],
  ["Translate"],
  ["Trip Planning"],
  ["Twitter"],
  ["UXUI Design"],
  ["Vacation"],
  ["Virtual Assistant"],
  ["Virtual Character"],
  ["Word(Msft)"],
  ["Writing"],
  ["YouTube"]
].map((o) => ({ tag: o[0] }));
